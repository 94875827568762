import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../../../Components/Common/Inputs/TextInput/TextInput";
import debounce from "../../../../infra/utils/debounce";

const SmartOrdersFilters = ({ filters, onFilterChange }) => {
  const [localFilters, setLocalFilters] = useState(filters);
  // Required for filter label display
  // const [value, setValue] = useState([]);
  const { t } = useTranslation();

  const debouncedFilterChange = useCallback(
    debounce((name, value) => {
      onFilterChange(name, value);
    }, 1000),
    [onFilterChange]
  );

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleLocalFilterChange = (name, value) => {
    setLocalFilters((prev) => ({ ...prev, [name]: value }));
    debouncedFilterChange(name, value);
  };

  // Required for Clear All button (clears filters and labels)
  // const handleClearAll = () => {
  //   setLocalFilters({});
  //   onFilterChange({});
  //   setValue([]);
  // };

  // Required for filter label display
  // const handleRemoveFilter = (filterName) => {
  //   const newFilters = { ...localFilters };
  //   delete newFilters[filterName];
  //   setLocalFilters(newFilters);
  //   onFilterChange(newFilters);
  // };

  return (
    <div
      className="smart-orders-filter-wrapper"
      style={{
        maxHeight: "calc(100vh - 185px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div className="d-flex flex-row justify-content-between">
        <div className="bodyText_2">{t("FILTERS")}</div>

        {/*  */}
        {/* Labels and clear all can be added when filters like rating are implemented */}
        {/*  */}

        {/* <div
          className="heading_5 text-info text-decoration-underline cursor-pointer"
          onClick={handleClearAll}
        >
          {t("CLEAR_ALL")}
        </div> */}
      </div>

      {/* Labels */}
      {/* <div className="filter-labels-wrapper">
        {Object.entries(localFilters).map(
          ([key, value]) =>
            value && (
              <div className="d-inline-block label-gap" key={key}>
                <div className="filter-label">
                  <div className="bodyText_11 text-white">{`${t(
                    key.toUpperCase()
                  )}: ${value}`}</div>
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <div className="labels-divider-vertical"></div>
                    <Icon
                      style={{ cursor: "pointer" }}
                      icon="closeIcon"
                      size={[16, 16]}
                      color="white"
                      onClick={() => handleRemoveFilter(key)}
                    />
                  </div>
                </div>
              </div>
            )
        )}
      </div> */}

      <div className="divider" />

      <div className="col-2">
        <div
          className="heading_3 mb-1"
          style={{ color: "rgba(68, 79, 89, 0.60)" }}
        >
          {t("REFERENCE_OR_SKU")}
        </div>
        <TextInput
          placeholder={`${t("SEARCH_REFERENCE_OR_SKU")}...`}
          type="text"
          onChange={(e) =>
            handleLocalFilterChange("referenceOrSku", e.target.value)
          }
          value={localFilters.referenceOrSku || ""}
          icon="searchIcon"
          iconSize={[14, 14]}
          noBorder
        />
      </div>
    </div>
  );
};

export default SmartOrdersFilters;
